// Generated by npm run gen:icons

export const IconArrowBlockUp = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowBlockUp"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M13.8,20h-3.6c-.3,0-.5,0-.7-.1-.2,0-.3-.3-.4-.4-.1-.2-.1-.4-.1-.7v-7.8h-2.7c-.2,0-.5-.1-.6-.4,0-.2,0-.5.1-.7l5.7-5.7c.2-.2.6-.2.9,0l5.7,5.7c.2.2.2.4.1.7s-.3.4-.6.4h-2.7v7.8c0,.3,0,.5-.1.7-.1.2-.3.3-.4.4-.2.1-.4.1-.7.1ZM7.7,9.7h1.8c.3,0,.6.3.6.6v8.4h0s0,0,0,0h3.7v-8.4c0-.3.3-.6.6-.6h1.8l-4.3-4.3-4.3,4.3Z" />
  </svg>
);

export const IconArrowLeft = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowLeft"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M11.72 4.045c-.076.023-.22.102-.32.176-.099.074-1.766 1.724-3.704 3.666-2.402 2.409-3.547 3.584-3.599 3.693-.1.215-.101.625 0 .84.105.224 7.259 7.378 7.483 7.483.211.099.627.101.824.004.19-.093.382-.284.488-.485.112-.213.117-.615.01-.842-.05-.108-.956-1.043-2.777-2.869l-2.702-2.71 5.918-.01c5.603-.01 5.927-.015 6.063-.083.191-.095.382-.286.488-.486.11-.21.117-.617.014-.828a1.268 1.268 0 0 0-.5-.501c-.139-.069-.438-.074-6.065-.084l-5.919-.01 2.685-2.69c1.853-1.855 2.713-2.745 2.777-2.869.118-.229.128-.629.022-.846a1.242 1.242 0 0 0-.493-.495 1.192 1.192 0 0 0-.693-.054" />
  </svg>
);

export const IconArrowNarrowDown = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowNarrowDown"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 16 16"
    role="img"
  >
    <path d="M7.902 2.187 C 7.759 2.219,7.643 2.304,7.573 2.428 L 7.507 2.547 7.500 7.333 L 7.493 12.119 5.900 10.529 C 5.024 9.654,4.266 8.918,4.215 8.893 C 3.995 8.782,3.696 8.874,3.577 9.089 C 3.501 9.228,3.493 9.417,3.560 9.549 C 3.620 9.669,7.607 13.664,7.754 13.752 C 7.910 13.844,8.090 13.844,8.246 13.752 C 8.393 13.664,12.380 9.669,12.440 9.549 C 12.647 9.137,12.183 8.688,11.772 8.902 C 11.709 8.934,11.026 9.598,10.093 10.532 L 8.520 12.107 8.507 7.327 L 8.493 2.547 8.423 2.426 C 8.315 2.244,8.096 2.143,7.902 2.187" />
  </svg>
);

export const IconArrowNarrowRight = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowNarrowRight"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M14,19c-.3,0-.5,0-.7-.3-.4-.4-.4-1,0-1.4l4.3-4.3H4c-.6,0-1-.4-1-1s.4-1,1-1h13.6l-4.3-4.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l6,6c0,0,.2.2.2.3,0,.1,0,.2,0,.4h0c0,.1,0,.3,0,.4,0,.1-.1.2-.2.3l-6,6c-.2.2-.5.3-.7.3Z" />
  </svg>
);

export const IconArrowNarrowUp = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconArrowNarrowUp"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 16 16"
    role="img"
  >
    <path d="M7.907 2.186 C 7.863 2.196,7.791 2.224,7.747 2.247 C 7.631 2.309,3.614 6.342,3.559 6.451 C 3.358 6.855,3.813 7.309,4.215 7.107 C 4.266 7.082,5.024 6.346,5.900 5.471 L 7.493 3.881 7.500 8.667 L 7.507 13.453 7.577 13.574 C 7.771 13.903,8.237 13.900,8.427 13.569 L 8.493 13.453 8.500 8.667 L 8.507 3.881 10.100 5.471 C 10.976 6.346,11.734 7.082,11.785 7.107 C 12.188 7.309,12.642 6.854,12.440 6.451 C 12.381 6.332,8.393 2.336,8.253 2.255 C 8.128 2.182,8.018 2.160,7.907 2.186" />
  </svg>
);

export const IconAsterisk = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconAsterisk"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M12,21c-.6,0-1-.4-1-1v-5.6l-4.3,4.3c-.4.4-1,.4-1.4,0s-.4-1,0-1.4l4.3-4.3h-5.6c-.6,0-1-.4-1-1s.4-1,1-1h5.6l-4.3-4.3c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l4.3,4.3v-5.6c0-.6.4-1,1-1s1,.4,1,1v5.6l4.3-4.3c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-4.3,4.3h5.6c.6,0,1,.4,1,1s-.4,1-1,1h-5.6l4.3,4.3c.4.4.4,1,0,1.4s-1,.4-1.4,0l-4.3-4.3v5.6c0,.6-.4,1-1,1Z" />
  </svg>
);

export const IconCheckHeart = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCheckHeart"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M12,21.7c-.7,0-1.3-.4-1.7-.8-2.3-2-6.3-5.7-7.8-7.6C.1,10.2.6,6,3.5,3.5c2.5-2.1,6-2,8.5.2,2.5-2.2,5.9-2.3,8.5-.2,2.9,2.4,3.4,6.6,1,9.6-1.5,1.9-5.5,5.8-7.8,7.8-.4.4-1,.8-1.7.8ZM7.6,4c-.9,0-1.9.3-2.8,1.1-2.1,1.8-2.4,4.7-.8,6.9,1.5,1.9,5.8,5.8,7.6,7.4.2.2.3.3.4.4,0,0,6.4-5.9,7.9-7.8,1.7-2.2,1.4-5.1-.7-6.9-2.2-1.9-4.9-1.1-6.4.7-.4.4-1.1.4-1.5,0-.9-1.1-2.3-1.8-3.7-1.8ZM11,14c-.3,0-.5,0-.7-.3l-2-2c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l1.3,1.3,3.8-3.8c.4-.4,1-.4,1.4,0,.4.4.4,1,0,1.4l-4.5,4.5c-.2.2-.5.3-.7.3Z" />
  </svg>
);

export const IconCheckVerified = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCheckVerified"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M8.7,23c-.4,0-.8,0-1.2-.2-.7-.3-1.3-.9-1.6-1.6l-.7-1.7c-.1-.3-.3-.5-.6-.6l-1.7-.7c-.7-.3-1.3-.9-1.6-1.6-.3-.8-.3-1.6,0-2.3l.7-1.7c.1-.3.1-.5,0-.8l-.7-1.7c-.2-.4-.2-.8-.2-1.2s0-.8.2-1.2c.2-.4.4-.7.7-1,.3-.3.6-.5,1-.7l1.7-.7c.3-.1.5-.3.6-.6l.7-1.7c.3-.7.9-1.3,1.6-1.6.7-.3,1.6-.3,2.3,0l1.7.7c.3.1.5.1.8,0l1.7-.7c.7-.3,1.6-.3,2.3,0,.7.3,1.3.9,1.6,1.6l.7,1.7s0,0,0,0c.1.3.3.5.6.6l1.7.7c.7.3,1.3.9,1.6,1.6.3.7.3,1.6,0,2.3l-.7,1.7c-.1.3-.1.5,0,.8l.7,1.7c.2.4.2.8.2,1.2s0,.8-.2,1.2c-.2.4-.4.7-.7,1-.3.3-.6.5-1,.7l-1.7.7c-.3.1-.5.3-.6.6l-.7,1.7c-.3.7-.9,1.3-1.6,1.6-.7.3-1.6.3-2.3,0l-1.7-.7c-.3-.1-.5-.1-.8,0l-1.7.7c-.4.2-.8.2-1.2.2ZM8.7,3c-.1,0-.3,0-.4,0-.3.1-.5.3-.6.6l-.7,1.7c-.3.7-.9,1.3-1.6,1.6l-1.7.7c-.1,0-.2.1-.3.2s-.2.2-.2.3c0,.1,0,.3,0,.4s0,.3,0,.4l.7,1.7c.3.7.3,1.6,0,2.3l-.7,1.7c-.1.3-.1.5,0,.8.1.2.3.5.6.6l1.7.7c.7.3,1.3.9,1.6,1.6l.7,1.7c.1.3.3.5.6.6.3.1.5.1.8,0l1.7-.7c.7-.3,1.6-.3,2.3,0l1.7.7c.3.1.5.1.8,0,.3-.1.5-.3.6-.6l.7-1.7c.3-.8.9-1.3,1.6-1.6l1.7-.7c.1,0,.2-.1.3-.2,0,0,.2-.2.2-.3,0-.1,0-.3,0-.4s0-.3,0-.4l-.7-1.7c-.3-.7-.3-1.6,0-2.3l.7-1.7c.1-.3.1-.5,0-.8-.1-.3-.3-.5-.6-.6l-1.7-.7c-.7-.3-1.3-.9-1.6-1.6,0,0,0,0,0,0l-.7-1.7c-.1-.3-.3-.5-.6-.6-.3-.1-.5-.1-.8,0l-1.7.7c-.7.3-1.6.3-2.3,0l-1.7-.7c-.1,0-.3,0-.4,0ZM11,15c-.3,0-.5,0-.7-.3l-2-2c-.4-.4-.4-1,0-1.4s1-.4,1.4,0l1.3,1.3,3.8-3.8c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-4.5,4.5c-.2.2-.5.3-.7.3Z" />
  </svg>
);

export const IconCheckmark = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCheckmark"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    role="img"
  >
    <path
      d="M9,18c-0.3,0-0.5-0.1-0.7-0.3l-5-5c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L9,15.6L19.3,5.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4
	l-11,11C9.5,17.9,9.3,18,9,18z"
    />
  </svg>
);

export const IconCheckmarkSmall = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCheckmarkSmall"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 14 14"
    role="img"
  >
    <path d="M5.2,10.9h0c-.3,0-.5-.1-.7-.3l-2.9-2.9c-.4-.4-.4-1,0-1.4.4-.4,1-.4,1.4,0l2.2,2.2,5.7-5.7c.4-.4,1-.4,1.4,0s.4,1,0,1.4l-6.4,6.4c-.2.2-.4.3-.7.3Z" />
  </svg>
);

export const IconChevronRight = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconChevronRight"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 20 20"
    role="img"
    fill="#475467"
  >
    <path d="M7.5,15.8c-.2,0-.4,0-.6-.2-.3-.3-.3-.9,0-1.2l4.4-4.4-4.4-4.4c-.3-.3-.3-.9,0-1.2.3-.3.9-.3,1.2,0l5,5c.3.3.3.9,0,1.2l-5,5c-.2.2-.4.2-.6.2Z" />
  </svg>
);

export const IconClock = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconClock" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M12,23c-6.1,0-11-4.9-11-11S5.9,1,12,1s11,4.9,11,11-4.9,11-11,11ZM12,3C7,3,3,7,3,12s4,9,9,9,9-4,9-9S17,3,12,3ZM16,15c-.2,0-.3,0-.4-.1l-4-2c-.3-.2-.6-.5-.6-.9v-6c0-.6.4-1,1-1s1,.4,1,1v5.4l3.4,1.7c.5.2.7.8.4,1.3-.2.4-.5.6-.9.6Z" />
  </svg>
);

export const IconCompass = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCompass"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M12,23c-6.07,0-11-4.93-11-11S5.93,1,12,1s11,4.93,11,11-4.93,11-11,11ZM12,3C7.04,3,3,7.04,3,12s4.04,9,9,9,9-4.04,9-9S16.96,3,12,3Z" />
    <path d="M8.5,16.94c-.15,0-.3-.02-.45-.08-.42-.15-.75-.48-.91-.91-.17-.49-.03-.91.18-1.54l1.49-4.46c.06-.17.11-.34.23-.51.1-.15.24-.29.39-.39.17-.12.33-.17.51-.23l4.47-1.49h0l.32.95-.31-.95c.64-.21,1.05-.35,1.54-.18.42.15.76.48.91.91.17.48.03.9-.17,1.53l-1.49,4.48c-.06.17-.11.34-.23.51-.11.16-.24.29-.4.4-.18.12-.34.17-.52.23l-4.46,1.49c-.44.15-.77.26-1.1.26ZM9.06,15.55h0ZM10.67,10.67l-1.33,3.99,3.99-1.33,1.33-3.99-3.99,1.33ZM15.55,9.06h0ZM14.72,8.27l.15.45s0,0,0,0l-.15-.44Z" />
  </svg>
);

export const IconCornerDownLeft = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCornerDownLeft"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 16 16"
    role="img"
  >
    <path d="M6,13.8c-.1,0-.3,0-.4-.1l-3.3-3.3s0,0,0,0h0s0,0,0-.1c0,0,0-.1,0-.2s0-.1,0-.2c0,0,0-.1.1-.2,0,0,0,0,0,0l3.3-3.3c.2-.2.5-.2.7,0s.2.5,0,.7l-2.5,2.5h3.1c2.2,0,3.2,0,4-.4.7-.3,1.2-.9,1.5-1.5.4-.7.4-1.8.4-4v-.9c0-.3.2-.5.5-.5s.5.2.5.5v.9c0,2.3,0,3.5-.5,4.4-.4.9-1.1,1.5-2,2-1,.5-2.1.5-4.4.5h-3.1l2.5,2.5c.2.2.2.5,0,.7s-.2.1-.4.1Z" />
  </svg>
);

export const IconCross = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconCross"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    role="img"
  >
    <g>
      <path d="M15,5L5,15 M5,5l10,10" />
      <path
        d="M15,15.8c-0.2,0-0.4-0.1-0.6-0.2L10,11.2l-4.4,4.4c-0.3,0.3-0.9,0.3-1.2,0c-0.3-0.3-0.3-0.9,0-1.2L8.8,10L4.4,5.6
		c-0.3-0.3-0.3-0.9,0-1.2c0.3-0.3,0.9-0.3,1.2,0L10,8.8l4.4-4.4c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2L11.2,10l4.4,4.4
		c0.3,0.3,0.3,0.9,0,1.2C15.4,15.8,15.2,15.8,15,15.8z"
      />
    </g>
  </svg>
);

export const IconDot = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconDot" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M11.9999 18.6667C15.6818 18.6667 18.6666 15.6819 18.6666 12C18.6666 8.31811 15.6818 5.33334 11.9999 5.33334C8.31802 5.33334 5.33325 8.31811 5.33325 12C5.33325 15.6819 8.31802 18.6667 11.9999 18.6667Z" />
  </svg>
);

export const IconError = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconError"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
    fill="#d03651"
  >
    <path d="M12,22.9c-.1,0-.3,0-.4,0-.3,0-.5-.2-.8-.4l-7.4-4.1c-.4-.2-.6-.3-.8-.6-.2-.2-.3-.4-.4-.7-.1-.3-.1-.6-.1-1V7.9c0-.4,0-.7.1-1,0-.3.2-.5.4-.7.2-.2.5-.4.8-.6L10.7,1.6c.3-.2.6-.3.9-.4.3,0,.5,0,.8,0,.3,0,.5.2.9.4l7.4,4.1c.3.2.6.3.8.6.2.2.3.5.4.7,0,.3,0,.6,0,1v8.1c0,.4,0,.7-.1,1,0,.3-.2.5-.4.7-.2.2-.5.4-.8.6l-7.4,4.1c-.3.2-.6.3-.9.4-.1,0-.3,0-.4,0ZM12,3.1s-.1,0-.3.2l-7.4,4.1c-.2,0-.3.1-.3.2,0,0,0,.2,0,.3v8.1c0,.2,0,.3,0,.4,0,0,.1,0,.3.1l7.4,4.1c.2,0,.2.1.3.2,0,0,.1,0,.3-.2l7.4-4.1c.2,0,.3-.1.3-.2,0,0,0-.2,0-.3V7.9c0-.2,0-.3,0-.4,0,0-.1,0-.3-.1l-7.4-4.1c-.1,0-.2-.1-.3-.2ZM12,17c-.6,0-1-.4-1-1s.4-1,1-1h0c.6,0,1,.4,1,1s-.4,1-1,1ZM12,13c-.6,0-1-.4-1-1v-4c0-.6.4-1,1-1s1,.4,1,1v4c0,.6-.4,1-1,1Z" />
  </svg>
);

export const IconFile = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconFile" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M15.2,23h-6.4c-1.84,0-2.76,0-3.62-.44-.76-.39-1.36-.99-1.75-1.75-.44-.86-.44-1.78-.44-3.62V6.8c0-1.84,0-2.76.44-3.62.39-.76.99-1.36,1.75-1.75.86-.44,1.78-.44,3.62-.44h3.21c.79,0,1.22,0,1.68.11.41.1.8.26,1.16.48.4.25.71.55,1.26,1.1l3.2,3.2c.55.55.86.86,1.11,1.27.22.36.38.75.48,1.16.11.46.11.89.11,1.68v7.21c0,1.84,0,2.76-.44,3.62-.38.76-.99,1.36-1.75,1.75-.86.44-1.78.44-3.62.44ZM8.8,3c-1.47,0-2.28,0-2.71.22-.38.19-.68.5-.87.87-.22.43-.22,1.24-.22,2.71v10.4c0,1.47,0,2.28.22,2.71.19.38.5.68.87.87.43.22,1.24.22,2.71.22h6.4c1.47,0,2.28,0,2.71-.22.38-.19.68-.5.87-.87.22-.43.22-1.24.22-2.71v-7.21c0-.47,0-.77-.02-.99h-3.38c-.65,0-1.08,0-1.51-.22-.38-.19-.68-.5-.87-.87-.22-.43-.22-.86-.22-1.51v-3.38c-.22-.02-.52-.02-.99-.02h-3.21ZM15,4.41v1.99c0,.28,0,.57.02.65.01-.05.3-.05.58-.05h1.99l-2.59-2.59ZM14,18h-6c-.55,0-1-.45-1-1s.45-1,1-1h6c.55,0,1,.45,1,1s-.45,1-1,1ZM16,14h-8c-.55,0-1-.45-1-1s.45-1,1-1h8c.55,0,1,.45,1,1s-.45,1-1,1Z" />
  </svg>
);

export const IconFilter = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    role="img"
    data-icon="IconFilter"
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.82125 4.72239C2.19097 4.01796 1.87583 3.66574 1.86394 3.3664C1.85361 3.10636 1.96536 2.85643 2.16603 2.69074C2.39704 2.5 2.86966 2.5 3.81491 2.5H16.184C17.1293 2.5 17.6019 2.5 17.8329 2.69074C18.0336 2.85643 18.1453 3.10636 18.135 3.3664C18.1231 3.66574 17.808 4.01796 17.1777 4.72239L12.4225 10.037C12.2968 10.1774 12.234 10.2477 12.1892 10.3276C12.1495 10.3984 12.1203 10.4747 12.1027 10.554C12.0828 10.6435 12.0828 10.7377 12.0828 10.9261V15.382C12.0828 15.5449 12.0828 15.6264 12.0565 15.6969C12.0333 15.7591 11.9955 15.8149 11.9463 15.8596C11.8907 15.9102 11.815 15.9404 11.6637 16.001L8.83039 17.1343C8.5241 17.2568 8.37096 17.3181 8.24802 17.2925C8.14052 17.2702 8.04617 17.2063 7.98551 17.1148C7.91613 17.0101 7.91613 16.8452 7.91613 16.5153V10.9261C7.91613 10.7377 7.91613 10.6435 7.89623 10.554C7.87858 10.4747 7.84943 10.3984 7.8097 10.3276C7.76491 10.2477 7.70209 10.1774 7.57645 10.037L2.82125 4.72239Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconHamburger = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconHamburger"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="m 2.9999999,7.5 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 H 21 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z m 0,7 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 H 21 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" />
    <path d="M 3,8.4545661 H 21 M 3,15.454566 h 18" />
  </svg>
);

export const IconHourglass = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconHourglass"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M20,23H4c-.6,0-1-.4-1-1s.4-1,1-1h1v-1.8c0-.9,0-1.4.1-1.9.1-.5.3-.9.6-1.3.3-.4.7-.7,1.4-1.3l3.4-2.8-3.4-2.8c-.7-.6-1.1-.9-1.4-1.3-.3-.4-.5-.8-.6-1.3-.1-.5-.1-1-.1-1.9v-1.8h-1c-.6,0-1-.4-1-1s.4-1,1-1h16c.6,0,1,.4,1,1s-.4,1-1,1h-1v1.8c0,.9,0,1.4-.1,1.9-.1.5-.3.9-.6,1.3-.3.4-.7.7-1.3,1.3l-3.4,2.8,3.4,2.8c.7.6,1.1.9,1.4,1.3.3.4.5.8.6,1.3.1.5.1,1,.1,1.9v1.8h1c.6,0,1,.4,1,1s-.4,1-1,1ZM7,21h10v-1.8c0-.7,0-1.1,0-1.4,0-.2-.2-.4-.3-.6-.2-.2-.4-.5-1-.9l-3.6-3-3.6,3c-.6.5-.9.7-1,.9-.1.2-.2.4-.3.6,0,.3,0,.6,0,1.4v1.8ZM7,3v1.8c0,.7,0,1.1,0,1.4,0,.2.2.4.3.6.2.2.4.5,1,.9l3.6,3,3.6-3c.6-.5.9-.7,1-.9.1-.2.2-.4.3-.6,0-.3,0-.6,0-1.4v-1.8H7Z" />
  </svg>
);

export const IconInfo = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconInfo" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M12,23c-6.1,0-11-4.9-11-11S5.9,1,12,1s11,4.9,11,11-4.9,11-11,11ZM12,3C7,3,3,7,3,12s4,9,9,9,9-4,9-9S17,3,12,3ZM12,17c-.6,0-1-.4-1-1v-4c0-.6.4-1,1-1s1,.4,1,1v4c0,.6-.4,1-1,1ZM12,9c-.6,0-1-.4-1-1s.4-1,1-1h0c.6,0,1,.4,1,1s-.4,1-1,1Z" />
  </svg>
);

export const IconLaptop = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconLaptop"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="m 6.1992187,3 c -1.12011,0 -1.818179,-0.052092 -2.5605468,0.3261719 -0.5643916,0.2875573 -1.0249427,0.7481084 -1.3125,1.3125 C 1.9479054,5.3810453 2,6.0791188 2,7.1992187 V 15.347656 C 1.5658471,15.611734 1.2031937,15.979217 1.0683594,16.482422 0.98164592,16.805911 1,17.023984 1,17.333984 c 0,0.62 -0.0191869,0.992786 0.1015625,1.44336 0.2769791,1.033747 1.0874355,1.844026 2.1210938,2.121093 a 1.0001,1.0001 0 0 0 0.00195,0 C 3.6750561,21.01905 4.0460356,21 4.6660156,21 H 19.333984 c 0.62,0 0.990948,0.01907 1.441407,-0.101563 a 1.0001,1.0001 0 0 0 0.002,0 c 1.033674,-0.277064 1.844029,-1.087419 2.121093,-2.121093 a 1.0001,1.0001 0 0 0 0,-0.002 C 23.019068,18.324932 23,17.953984 23,17.333984 c 0,-0.31 0.01847,-0.527935 -0.06836,-0.851562 C 22.796863,15.979046 22.434182,15.611731 22,15.347656 V 7.1992187 C 22,6.0791188 22.052115,5.3810508 21.673828,4.6386719 21.386272,4.0741651 20.925654,3.6137188 20.361328,3.3261719 19.618932,2.9478761 18.920881,3 17.800781,3 Z m 0,2 H 17.800781 c 1.1201,0 1.53914,0.051691 1.652344,0.109375 0.188274,0.095933 0.341656,0.2493467 0.4375,0.4375 C 19.948338,5.6601361 20,6.0791188 20,7.1992187 V 15 H 4 V 7.1992187 C 4,6.0791187 4.0516615,5.6601416 4.109375,5.546875 4.2052977,5.3586066 4.3586066,5.2052977 4.546875,5.109375 4.6601472,5.0516587 5.0791088,5 6.1992187,5 Z M 3,17 c -0.06915,0.01854 0.023994,0 0.3339844,0 H 20.666016 c 0.31,0 0.403411,0.01863 0.333984,0 -0.01863,-0.06943 0,0.02398 0,0.333984 0,0.618694 -0.01765,0.865012 -0.0332,0.923828 -0.09294,0.346727 -0.362258,0.616049 -0.708985,0.708985 C 20.198996,18.982346 19.952678,19 19.333984,19 H 4.6660156 c -0.6186737,0 -0.8649408,-0.01764 -0.9238281,-0.0332 h -0.00195 C 3.3942299,18.873512 3.1259558,18.605939 3.0332031,18.259766 a 1.0001,1.0001 0 0 0 0,-0.002 C 3.0176525,18.199786 3,17.953984 3,17.333984 3,17.023984 3.0185735,16.930711 3,17 Z" />
  </svg>
);

export const IconLock = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconLock" {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img">
    <path d="M11.5 2.043c-2.758.216-5.014 2.334-5.42 5.089-.025.171-.058.7-.073 1.176l-.027.864-.257.07c-1.156.316-2.245 1.457-2.52 2.638-.169.727-.181.954-.181 3.62 0 2.666.012 2.893.181 3.62.289 1.245 1.429 2.384 2.68 2.677.778.183.79.183 6.117.183s5.339 0 6.117-.183c.72-.169 1.484-.665 1.983-1.289.459-.573.693-1.158.825-2.068.071-.481.071-5.401.001-5.88-.134-.908-.365-1.489-.825-2.07-.449-.567-1.191-1.075-1.824-1.248l-.257-.07-.027-.864a15.432 15.432 0 0 0-.073-1.176 5.965 5.965 0 0 0-1.746-3.416c-1.26-1.221-2.91-1.812-4.674-1.673m1.56 2.094c1.396.41 2.385 1.399 2.804 2.803.098.328.109.435.126 1.21l.019.85H7.991l.019-.85c.017-.775.028-.882.126-1.21.204-.683.493-1.196.944-1.675a3.955 3.955 0 0 1 1.716-1.083c.501-.156.688-.179 1.324-.164.483.011.64.031.94.119m4.517 6.955c.342.102.586.247.835.496.257.256.4.503.497.856.065.233.071.503.071 3.056s-.006 2.823-.071 3.056c-.097.353-.24.6-.497.856a1.78 1.78 0 0 1-.856.497c-.239.066-.613.071-5.556.071s-5.317-.005-5.556-.071a1.78 1.78 0 0 1-.856-.497 1.78 1.78 0 0 1-.497-.856c-.065-.233-.071-.503-.071-3.056s.006-2.823.071-3.056c.097-.352.242-.603.49-.849.346-.342.645-.486 1.145-.553.14-.018 2.585-.03 5.434-.027 4.819.006 5.196.012 5.417.077m-5.999 2.523c-.216.1-.391.28-.494.505-.054.119-.064.329-.064 1.38 0 1.354.003 1.371.242 1.656.357.422 1.119.422 1.476 0 .239-.285.242-.302.242-1.656 0-1.051-.01-1.261-.064-1.38a1 1 0 0 0-1.338-.505" />
  </svg>
);

export const IconMinus = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconMinus" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 14 14" role="img">
    <path d="M2.730 6.023 C 2.454 6.069,2.147 6.301,2.023 6.559 C 1.898 6.817,1.891 7.154,2.005 7.408 C 2.071 7.557,2.224 7.732,2.378 7.835 C 2.650 8.017,2.334 8.006,7.082 7.998 L 11.305 7.992 11.437 7.938 C 11.696 7.834,11.920 7.609,12.019 7.356 C 12.092 7.167,12.093 6.834,12.020 6.646 C 11.951 6.470,11.831 6.318,11.662 6.193 C 11.387 5.991,11.817 6.009,7.070 6.005 C 4.629 6.003,2.804 6.011,2.730 6.023 " />
  </svg>
);

export const IconPencil = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconPencil"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M5.84,19.65c-.26,0-.52-.1-.71-.29-.21-.21-.32-.52-.29-.82l.25-2.26c.03-.31.06-.51.13-.74.07-.2.16-.39.27-.57.13-.2.28-.35.5-.56l6.79-6.79s0,0,.01-.01c0,0,0,0,.01-.01l1.99-1.99c1.12-1.12,2.96-1.13,4.08,0s1.12,2.96,0,4.08l-8.8,8.8c-.22.22-.36.36-.56.5-.18.12-.37.21-.57.27-.22.07-.42.09-.71.13l-2.28.25s-.07,0-.11,0ZM13.51,9.73l-6.1,6.1c-.12.12-.19.18-.24.25-.06.16-.06.25-.08.42l-.11,1.02,1.01-.11c.18-.02.27-.02.34-.04.15-.08.21-.15.33-.28l6.1-6.1-1.25-1.25ZM14.92,8.31l1.25,1.25,1.29-1.29c.34-.35.34-.91,0-1.25s-.91-.35-1.25,0l-1.29,1.29Z" />
  </svg>
);

export const IconPlus = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconPlus" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 20" role="img">
    <path d="M9.768 3.37a.905.905 0 0 0-.564.58c-.026.092-.037.915-.037 2.674v2.541l-2.609.009c-2.914.01-2.715-.006-2.969.248a.716.716 0 0 0-.237.578c0 .252.062.403.237.578.254.254.055.238 2.969.248l2.607.009.009 2.607c.01 2.914-.006 2.715.248 2.969a.716.716 0 0 0 .578.237.716.716 0 0 0 .578-.237c.254-.254.238-.055.248-2.969l.009-2.607 2.607-.009c2.914-.01 2.715.006 2.969-.248a.716.716 0 0 0 .237-.578.716.716 0 0 0-.237-.578c-.254-.254-.055-.238-2.969-.248l-2.607-.009-.009-2.607c-.009-2.443-.013-2.616-.07-2.73a1.046 1.046 0 0 0-.412-.412.997.997 0 0 0-.576-.046" />
  </svg>
);

export const IconThumbsUp = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconThumbsUp"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M17.4,23H4c-1.7,0-3-1.3-3-3v-7c0-1.7,1.3-3,3-3h2.4l3.5-7.9c.3-.7.9-1.1,1.7-1.1,1.9,0,3.5,1.6,3.5,3.5v3.5h3.5c1.2,0,2.3.5,3,1.4s1.1,2.1.9,3.2l-1.1,7c-.3,2-2,3.4-4,3.4ZM8,21h9.4c1,0,1.8-.7,2-1.7l1.1-7c0-.6,0-1.2-.5-1.6-.4-.4-.9-.7-1.5-.7h-3.5c-1.1,0-2-.9-2-2v-3.5c0-.8-.6-1.4-1.4-1.5l-3.5,7.8c0,.1-.1.2-.2.3v9.9ZM4,12c-.6,0-1,.4-1,1v7c0,.6.4,1,1,1h2v-9h-2Z" />
  </svg>
);

export const IconTrash = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconTrash" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M7,5C7.007,3.768 7.075,3.133 7.327,2.638C7.615,2.074 8.074,1.615 8.638,1.327C9.159,1.061 9.835,1 11.2,1L12.8,1C14.165,1 14.841,1.061 15.362,1.327C15.926,1.615 16.385,2.074 16.673,2.638C16.925,3.133 16.993,3.768 17,5L21,5C21.552,5 22,5.448 22,6C22,6.552 21.552,7 21,7L20,7L20,17.2C20,19.125 19.939,20.081 19.564,20.816C19.181,21.569 18.569,22.181 17.816,22.564C17.081,22.939 16.125,23 14.2,23L9.8,23C7.875,23 6.919,22.939 6.184,22.564C5.431,22.181 4.819,21.569 4.436,20.816C4.061,20.081 4,19.125 4,17.2L4,7L3,7C2.448,7 2,6.552 2,6C2,5.448 2.448,5 3,5L7,5ZM18,7L6,7L6,17.2C6,18.636 5.939,19.359 6.218,19.908C6.41,20.284 6.716,20.59 7.092,20.782C7.64,21.061 8.364,21 9.8,21L14.2,21C15.636,21 16.36,21.061 16.908,20.782C17.284,20.59 17.59,20.284 17.782,19.908C18.061,19.36 18,18.636 18,17.2L18,7ZM8.999,5L15.001,5C15.008,4.254 15.048,3.854 14.891,3.546C14.795,3.358 14.642,3.205 14.454,3.109C14.119,2.938 13.676,3 12.8,3L11.2,3C10.324,3 9.881,2.938 9.546,3.109C9.358,3.205 9.205,3.358 9.109,3.546C8.952,3.854 8.992,4.254 8.999,5ZM13,11.5C13,10.948 13.448,10.5 14,10.5C14.552,10.5 15,10.948 15,11.5L15,16.5C15,17.052 14.552,17.5 14,17.5C13.448,17.5 13,17.052 13,16.5L13,11.5ZM9,11.5C9,10.948 9.448,10.5 10,10.5C10.552,10.5 11,10.948 11,11.5L11,16.5C11,17.052 10.552,17.5 10,17.5C9.448,17.5 9,17.052 9,16.5L9,11.5Z" />
  </svg>
);

export const IconUser = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconUser" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M20,22c-.6,0-1-.4-1-1,0-1.3,0-1.9-.1-2.4-.3-1-1-1.7-2-2-.4-.1-1.1-.1-2.4-.1h-5c-1.3,0-1.9,0-2.4.1-1,.3-1.7,1-2,2-.1.4-.1,1.1-.1,2.4s-.4,1-1,1-1-.4-1-1c0-1.5,0-2.2.2-3,.5-1.6,1.7-2.8,3.3-3.3.7-.2,1.5-.2,3-.2h5c1.5,0,2.2,0,3,.2,1.6.5,2.8,1.7,3.3,3.3.2.7.2,1.5.2,3s-.4,1-1,1ZM12,13c-3,0-5.5-2.5-5.5-5.5s2.5-5.5,5.5-5.5,5.5,2.5,5.5,5.5-2.5,5.5-5.5,5.5ZM12,4c-1.9,0-3.5,1.6-3.5,3.5s1.6,3.5,3.5,3.5,3.5-1.6,3.5-3.5-1.6-3.5-3.5-3.5Z" />
  </svg>
);

export const IconUsers = (props: React.SVGAttributes<SVGElement>) => (
  <svg data-icon="IconUsers" {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24" role="img">
    <path d="M22,22c-.55,0-1-.45-1-1v-2c0-1.37-.92-2.56-2.25-2.91-.54-.14-.86-.68-.72-1.22.14-.54.69-.86,1.22-.72,2.21.57,3.75,2.56,3.75,4.84v2c0,.55-.45,1-1,1ZM17,22c-.55,0-1-.45-1-1,0-1.73,0-2.6-.23-3.15-.3-.73-.89-1.32-1.62-1.62-.55-.23-1.47-.23-3.15-.23h-3c-1.73,0-2.6,0-3.15.23-.73.3-1.32.89-1.62,1.62-.23.55-.23,1.42-.23,3.15,0,.55-.45,1-1,1s-1-.45-1-1c0-1.99,0-3,.38-3.91.5-1.22,1.49-2.2,2.71-2.71.92-.38,1.92-.38,3.91-.38h3c1.99,0,3,0,3.91.38,1.23.51,2.19,1.47,2.71,2.71.38.92.38,1.92.38,3.91,0,.55-.45,1-1,1ZM9.5,12c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5ZM9.5,4c-1.65,0-3,1.35-3,3s1.35,3,3,3,3-1.35,3-3-1.35-3-3-3ZM15.5,11.71c-.4,0-.77-.24-.93-.63-.21-.51.04-1.09.55-1.3,1.14-.46,1.88-1.55,1.88-2.78s-.74-2.32-1.88-2.78c-.51-.21-.76-.79-.55-1.3s.79-.76,1.3-.55c1.9.77,3.12,2.59,3.12,4.64s-1.23,3.87-3.12,4.64c-.12.05-.25.07-.38.07Z" />
  </svg>
);

export const IconWarning = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconWarning"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    role="img"
  >
    <path
      d="M20.2,21.5H3.8c-1.1,0-1.7,0-2.2-0.4c-0.5-0.3-0.8-0.8-0.8-1.4c-0.1-0.6,0.2-1.2,0.8-2.1L9.8,3.4l0,0l0.9,0.5L9.8,3.4
	c0.5-0.9,0.8-1.5,1.4-1.7c0.5-0.2,1.1-0.2,1.6,0c0.6,0.3,0.9,0.8,1.4,1.7l8.2,14.2c0.5,0.9,0.8,1.5,0.8,2.1
	c-0.1,0.6-0.4,1.1-0.8,1.4C21.9,21.5,21.3,21.5,20.2,21.5z M2.8,19.5c0.2,0,0.6,0,1,0h16.4c0.4,0,0.8,0,1,0
	c-0.1-0.2-0.3-0.5-0.5-0.9L12.5,4.4c-0.2-0.3-0.4-0.7-0.5-0.9c-0.1,0.2-0.3,0.5-0.5,0.9l-0.9-0.5l0.9,0.5L3.3,18.6
	C3.1,18.9,2.9,19.3,2.8,19.5z M12,18c-0.6,0-1-0.4-1-1s0.4-1,1-1h0c0.6,0,1,0.4,1,1S12.6,18,12,18z M12,14c-0.6,0-1-0.4-1-1V9
	c0-0.6,0.4-1,1-1s1,0.4,1,1v4C13,13.6,12.6,14,12,14z"
    />
  </svg>
);

export const IconWarningSquare = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    data-icon="IconWarningSquare"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    role="img"
  >
    <path d="M22,0H2C.9,0,0,.9,0,2v20c0,1.1.9,2,2,2h20c1.1,0,2-.9,2-2V2c0-1.1-.9-2-2-2ZM10.7,6.5h2.7v2.9l-.3,4.6h-2l-.3-4.6v-2.9ZM13.4,17.5h-2.8v-2.7h2.8v2.7Z" />
  </svg>
);
